import React, { Component } from 'react';
import * as ct from '../../shared/ClientConstants';

export class PlayerId extends Component {
  nameTrim(name) {
    if (!this.props.shortName || !name || name.length === 0) {
      return name;
    }
    const lastSpaceIndex = name.lastIndexOf(' ');
    return name[0] + '.' + name.substring(lastSpaceIndex);
  }

  render() {
    return (
      <div
        style={
          this.props.reversed
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                //alignText: 'right',
              }
            : undefined
        }
      >
        {this.props.proCode && !this.props.reversed ? (
          <img
            src={
              ct.API_URL_BASE + '/api/file/avatar?proCode=' + this.props.proCode
            }
            className='img-sm rounded-circle'
            loading='lazy'
          />
        ) : (
          <div />
        )}
        {this.props.countryCode && (
          <i
            className={`flag-icon flag-icon-${this.props.countryCode.toLowerCase()}`}
          />
        )}
        {this.props.bmabTitle ? ' ' + this.props.bmabTitle : ''}
        {this.props._id ? (
          <a
            href={
              this.props._id === 'login'
                ? '/account/login'
                : '/user/' + this.props._id
            }
            style={this.props.reversed && { marginRight: 3 }}
          >
            {this.props.reversed ? '' : ' '}
            {this.nameTrim(this.props.name)}
            {this.props.reversed ? ' ' : ''}
          </a>
        ) : (
          (this.props.reversed ? '' : ' ') +
          (this.nameTrim(this.props.name) || '') +
          (this.props.reversed ? ' ' : '')
        )}
        {this.props.proCode && this.props.reversed ? (
          <img
            src={
              ct.API_URL_BASE + '/api/file/avatar?proCode=' + this.props.proCode
            }
            alt='playerAvatar'
            className='img-sm rounded-circle'
            loading='lazy'
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default PlayerId;
